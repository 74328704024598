import React, { useContext } from 'react';
import { Link, Wayfinder } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import css from 'styled-jsx/css';
import { ContextPage } from '@lmig/dotcom-aspect-components/ContextPage';
import LinkEnvironmentalize from './LinkEnvironmentalize';

const { className: wayfinderClass, styles: wayfinderStyles } = css.resolve`
  .lm-Wayfinder {
    margin: 0;
    color: #343741;
    font-size: 0.875rem;
    padding: 1rem 0;
  }
  li.is-current {
    font-weight: normal;
  }
`;

const { className: linkClass, styles: linkStyles } = css.resolve`
  .lm-Link.lm-Link,
  .lm-Link.lm-Link:hover,
  .lm-Link.lm-Link:active,
  .lm-Link.lm-Link:focus {
    font-weight: bold;
  }
`;

const Breadcrumb = ({
  bgColor = 'yellow',
  breadcrumbList
}) => {
  const {
    content: { meta: { breadcrumb: breadcrumbOptions = true } = { breadcrumb: true } },
    page: { breadcrumb },
  } = useContext(ContextPage);
  const breadcrumbToUse = breadcrumbList || breadcrumb;

  if (!breadcrumbOptions || !breadcrumbToUse) {
    return null;
  }

  const itemsOnlyLinks = breadcrumbToUse.filter(({ url }) => url);

  if (!itemsOnlyLinks.length) {
    return null;
  }

  const newItems = itemsOnlyLinks.map(({ name, url }, idx) => (
    <span key={url} itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
      <meta itemProp="position" content={idx + 1} />
      {!!name &&
        (idx !== itemsOnlyLinks.length - 1 ? (
          <LinkEnvironmentalize domain="COMPARION">
            <Link className={linkClass} href={url} onBackground={bgColor} itemProp="item">
              <span itemProp="name">{name}</span>
            </Link>
          </LinkEnvironmentalize>
        ) : <span itemProp="name">{name}</span>)
      }
      {linkStyles}
    </span>
  ));

  // A name property is required in breadcrumb schema
  return itemsOnlyLinks.length > 1 && !!itemsOnlyLinks[0].name ? (
    <div itemProp="breadcrumb" itemScope itemType="https://schema.org/BreadcrumbList" data-testid="breadcrumb-schema">
      <Wayfinder className={wayfinderClass} items={newItems} />
      {wayfinderStyles}
    </div>
  ) : null;
};

Breadcrumb.propTypes = {
  bgColor: PropTypes.oneOf(['whites', 'yellow']),
  breadcrumbList: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

export default Breadcrumb;