import React from 'react';
import PropTypes from 'prop-types';
import css from 'styled-jsx/css';
import LmdsIcons from '@lmig/dotcom-aspect-components/Icon/LmdsIcons';
import Image from '@lmig/dotcom-aspect-components/Utility/Image';
import WrapperConditional from '@lmig/dotcom-aspect-components/Utility/WrapperConditional';
import { Link } from '@lmig/lmds-react';
import LinkEnvironmentalize from '../LinkEnvironmentalize';

const AgentImageSocials = ({
  image,
  socials,
  agentHref,
  mobileSizeOnly = false,
  imgLgSize = '15.625rem',
  imgSmSize = '7.5rem'
}) => {
  let agentImage = image;

  if (!image.src) {
    agentImage = {
      src: 'comparion/entity/agent/blank-profile-photo.jpg',
      alt: 'Placeholder Image, Insurance Agent | Comparion',
      height: 300,
      width: 300
    };
  }

  const linkHeadShot = wrapperChildren => (
    <LinkEnvironmentalize domain="COMPARION">
      <Link href={agentHref}>
        {wrapperChildren}
      </Link>
    </LinkEnvironmentalize>
  );

  const { className: imageClass, styles: imageStyles } = css.resolve`
  @import './global-styles/tokens';
  img.heroImage {${`
    max-height: ${imgSmSize};
    max-width: ${imgSmSize};
    min-height: 7.5rem;
    min-width: 7.5rem;
    width: 100%;
    margin: 0 auto;
    object-fit: cover;
    border: 0.5rem solid #E6E6E6;
  `}}
  @media screen and (min-width: $lm-breakpoint-md-min) {
    img.heroImage { ${mobileSizeOnly ? '' : `
      max-height: 15.875rem;
      max-width: 15.875rem;
      min-height: 13.125rem;
      min-width: 13.125rem;
      border-width: 1rem;
      `}
      margin: 0;
    }
  }
  @media screen and (min-width: $lm-breakpoint-lg-min) {
    img.heroImage { ${mobileSizeOnly ? '' : `
      min-height: ${imgLgSize};
      min-width: ${imgLgSize};
      `}
    }
  }
`;

  return (
    <div className="imageSocialWrapper">
      <div>
        <WrapperConditional condition={!!agentHref} wrapper={linkHeadShot}>
          <Image imageDomain="IMAGE_EMS" type="circle" preload className={imageClass}>
            <img
              className="heroImage"
              src={agentImage.src}
              alt={agentImage.alt}
              height={agentImage.height}
              width={agentImage.width}
              itemProp="image"
            />
          </Image>
        </WrapperConditional>
      </div>
      {socials && Object.keys(socials).length &&
        <div className="socialIcons">
          {Object.entries(socials).map(([site, href]) => href ? <a href={href} key={site} aria-label={site} className="socialIcon"><LmdsIcons icon={site.charAt(0).toUpperCase() + site.slice(1)} /></a> : null)}
        </div>
      }
      {imageStyles}
      <style jsx>{`@import './AgentImageSocials.scoped.scss';`}</style>
    </div>
  );
};

AgentImageSocials.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number
  }).isRequired,
  socials: PropTypes.shape({
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    linkedin: PropTypes.string,
    twitter: PropTypes.string
  }).isRequired,
  agentHref: PropTypes.string,
  mobileSizeOnly: PropTypes.bool,
  imgLgSize: PropTypes.string,
  imgSmSize: PropTypes.string,
};

export default AgentImageSocials;