import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-sticky-el';
import useThrottledHandler from '@lmig/dotcom-aspect-hooks/useThrottledHandler';
import { GridCol, GridRow } from '@lmig/lmds-react';
import css from 'styled-jsx/css';
import Breadcrumb from './Breadcrumb';

const getQuoteBoxStyles = (color) => css.resolve`
    * {
      background-color: ${color};
    }
  `;

const { className: stickyClass, styles: stickyStyles } = css.resolve`
  *:not(.sticky){
    transform: none !important;
  }
`;

const BreadcrumbSticky = ({
  constrainToParent = false,
  containerRef,
  backgroundColor,
  breadcrumbList
}) => {
  const [breakpoint, setBreakpoint] = useState('');

  const { className: quoteBoxClass, styles: quoteBoxStyles } = getQuoteBoxStyles(backgroundColor);

  const quoteBoxRef = React.useRef();
  const stickyRef = React.useRef();

  const verticalShadowAdjustment = 24;

  useThrottledHandler(
    'resize',
    () => {
      switch (true) {
        case window.innerWidth >= 960:
          if (breakpoint !== 'desktop') setBreakpoint('desktop');
          break;
        default:
          if (breakpoint !== 'mobile') setBreakpoint('mobile');
          break;
      }
    },
    { callOnInit: true },
  );

  useThrottledHandler(
    'scroll',
    () => {
      if (constrainToParent && breakpoint === 'desktop') {
        const quoteBox = quoteBoxRef.current;
        const stickyEl = stickyRef.current.wrapperEl;
        const quoteBoxContainer = containerRef?.current ?? quoteBox.parentNode;
        const containerRect = quoteBoxContainer.getBoundingClientRect();

        if (stickyEl) {
          const elRect = stickyEl.getBoundingClientRect();
          const bottom = elRect.height + verticalShadowAdjustment;
          const top = containerRect.bottom - bottom;

          if (containerRect.bottom <= bottom) {
            stickyEl.style.top = `${top}px`;
          } else {
            stickyEl.style.top = '0px';
          }
        }
      }
    },
    { callOnInit: true },
  );

  return (
    <div className={quoteBoxClass} ref={quoteBoxRef}>
      <Sticky
        stickyStyle={{
          top: '0px',
          width: '100%',
          position: 'fixed',
          backgroundColor: `${backgroundColor}`,
          zIndex: 100,
        }}
        ref={stickyRef}
        className={stickyClass}
      >
        <GridRow mainGrid>
          <GridCol>
            <Breadcrumb bgColor="whites" breadcrumbList={breadcrumbList} />
          </GridCol>
        </GridRow>
      </Sticky>
      {quoteBoxStyles}
      {stickyStyles}
    </div >
  );
};

BreadcrumbSticky.propTypes = {
  /** Locks component to parent */
  constrainToParent: PropTypes.bool,
  containerRef: PropTypes.shape({ current: PropTypes.instanceOf(PropTypes.node) }),
  backgroundColor: PropTypes.string,
  breadcrumbList: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

export default BreadcrumbSticky;